import React from 'react'
import Link from 'gatsby-link'

const Page2 = () => (
  <div>
    <h1 className="c-Link">Heeyyyy</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <Link to="/">Go to Index</Link>
  </div>
)

export default Page2
